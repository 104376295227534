import './Home.css'
const CoursesInfo = {
    'Registration': [
        {
            'name': "Youtube 好學者會員",
            'token': '395_cpp_oop_basics'
        }
    ],
    'CppBasics': [
        /*
        {
            title: "C++ 基礎程式設計",
            hours: "15 小時以上"
        },
        */
        {
            title: "C++ 進階程式設計",
            date: ["2025 / 02 / 19"],
            url: "https://train.csie.ntu.edu.tw/train/course.php?id=4339",
            enrolling: true,
            hours: "約 18 小時 / 179 部影片"
        },
        {
            title: "C++ 專案開發基礎實戰",
            url: "https://train.csie.ntu.edu.tw/train/course.php?id=4344",
            date: ["2025 / 02 / 19"],
            enrolling: true,
            hours: "約 7 小時 / 106 部影片"
        },
        {
            title: "C++ 演算法與程式解題入門",
            url: "https://train.csie.ntu.edu.tw/train/course.php?id=4493",
            date: ["2025 / 02 / 19"],
            enrolling: true,
            hours: "約 11 小時 / 128 部影片"
        },
        {
            title: "C++ 資料結構與泛型程式設計基礎",
            timeslot: "線上課程",
            date: ["2025 / 02 / 19"],
            enrolling: true,
            url: "https://train.csie.ntu.edu.tw/train/course.php?id=4397",
            hours: "約 9 小時 / 91 部影片"
        },
        {
            title: "C++ 多執行緒與並行程式設計基礎",
            date: ["2025 / 02 / 19"],
            url: "https://train.csie.ntu.edu.tw/train/course.php?id=4346",
            enrolling: true,
            hours: "約 8 小時 / 73 部影片"
        },
        {
            title: "C++ 物件導向與設計模式基礎",
            date: ["2025 / 02 / 19"],
            url: "https://train.csie.ntu.edu.tw/train/course.php?id=4492",
            hours: "約 5 小時 / 51 部影片",
            enrolling: true
        }
    ],
    'Others': [
        {
            title: "Python 演算法與程式解題",
            timeslot: "週末兩天課程",
            hours: "共 10 小時 / 週末 2 天",
            date: ["2025 / 03 / 15"],
            enrolling: true,
            url: "https://train.csie.ntu.edu.tw/train/course.php?id=5126&bid=436"
        },
        {
            title: "Go 語言程式設計",
            timeslot: "週末兩天課程",
            hours: "共 10 小時 / 週末 2 天",
            date: ["2025 / 03 / 08"],
            enrolling: true,
            url: "https://train.csie.ntu.edu.tw/train/course.php?id=5125&bid=436"
        }

        /*
        {
            title: "Python 基礎程式設計",
            timeslot: "線上課程",
            hours: "15 小時以上影片"
        },
        {
            title: "Python 進階程式設計",
            timeslot: "線上課程",
            url: "https://train.csie.ntu.edu.tw/train/course.php?id=4459",
            // date: ["2024 / 09 / 11"],
            hours: "15 小時以上影片"
        },

        {
            title: "C 語言進階程式設計",
            timeslot: "六日下午",
            hours: "共 10 小時 / 2 天"
        },
        {
            title: "C# 進階程式設計",
            timeslot: "線上課程",
            hours: "15 小時以上影片"
        }, 
        {
            title: "Rust 基礎程式設計",
            timeslot: "線上課程",
            hours: "15 小時以上影片"
        }
        */
    ]
}

export default CoursesInfo